<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("DAILY_TASKS") }}
        </div>

        <div class="card-toolbar"></div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          group-by="storeName"
          show-group-by
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingFridges"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
              v-if="$store.state.auth.user.roleId == 1"
            >
              <template v-slot:prepend>
                <b-col>
                  <v-autocomplete
                    hide-details
                    v-if="_.size(stores) > 0"
                    :items="stores"
                    :label="$t('STORE')"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="filters.storeId"
                  ></v-autocomplete>
                </b-col>
              </template>
            </SearchTools>
          </template>

          <template v-slot:item.taskName="{ item }">
            {{ item.taskName }}
          </template>

          <template v-slot:item.storeName="{ item }">
            {{ item.storeName }}
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDateTime }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <v-dialog
                    v-model="dialogEdit"
                    max-width="600px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        x-small
                        @click="edit(item)"
                      >
                        <v-icon color="green"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{
                          $t("DAILY_TASK") + " - " + storeName
                        }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="form.comment"
                                :label="$t('COMMENT')"
                                hide-details
                                single-line
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" @click="update()">
                          {{ $t("TASK_DONE") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  GET_STORE_DAILY_TASKS_DASHBOARD,
  UPDATE_STORE_DAILY_TASK
} from "@/modules/daily-tasks/store/daily-tasks.module";
//Fetch Fridges
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import moment from "moment/moment";

export default {
  name: "DailyTasksByStore",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      firstLoader: true,
      search: "",
      dialogEdit: false,
      items: [],
      stores: [],
      totalItems: 500,
      searchQuery: "",
      storeName: "",
      form: {
        comment: ""
      },
      submitForm: "",
      filters: {
        name: "",
        storeId: null
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: 50,
        totalItems: 500
      },
      rowsPerPageItems: [50, 100, 150, 200, 250],
      columns: {},
      headers: [
        { text: "Name", value: "taskName", sortable: false, groupable: false },
        {
          text: "Store",
          value: "storeName"
        },
        {
          text: "Created",
          value: "createdAt",
          sortable: false,
          groupable: false
        },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px",
          groupable: false
        }
      ]
    };
  },
  components: {
    SearchTools
  },
  mounted() {
    let vm = this;
    vm.getSelectsStores();
  },
  computed: {
    ...mapGetters(["getStoreDailyTasks", "isLoadingStoreDailyTasks"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        storeId: this.filters.storeId
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogEdit(val) {
      val || this.close();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogEdit = false;
    },
    edit(item) {
      this.submitForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    update() {
      let vm = this;
      let id = vm.submitForm.id;
      let payload = {
        comment: vm.form.comment,
        is_completed: 1
      };

      this.$store
        .dispatch(UPDATE_STORE_DAILY_TASK, { id: id, payload: payload })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.form.comment = "";
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.storeName = vm.stores[0].name;
      vm.workDayDate = moment().format("YYYY-MM-DD");
      vm.searchQuery = "";
      vm.filters.storeId = "";
      vm.filters.workDay = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;

      vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(GET_STORE_DAILY_TASKS_DASHBOARD, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.fetchStoreName();
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getSelectsStores() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
          vm.fetchStoreName();
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    fetchStoreName() {
      let vm = this;
      if (vm.filters.storeId == "" || vm.filters.storeId == null) {
        if (vm.stores.length != 0) {
          vm.storeName = vm.stores[0].name;
        }
      } else {
        vm.storeName = vm.stores.find(function(store) {
          return store.id === vm.filters.storeId;
        }).name;
      }
    }
  }
};
</script>
