<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-xxl-12">
        <UserAlertBox></UserAlertBox>
        <DailyTasksByStore></DailyTasksByStore>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { PROJECT_NAME } from "@/core/config/config.js";
import DailyTasksByStore from "@/modules/daily-tasks/components/StoreTasksListOnDashboard.vue";
import UserAlertBox from "@/view/content/alertbox/UserContractAlert.vue";
export default {
  name: "dashboard",
  components: { UserAlertBox, DailyTasksByStore },
  computed: {
    projectName() {
      return PROJECT_NAME;
    }
  },
  mounted() {},
  methods: {}
};
</script>
